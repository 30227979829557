import { useMemo, memo, useRef } from "react"
import { Avatar, Box, Button, Flex, Heading, HStack, Img, Text, VStack, Spinner } from "@chakra-ui/react"
import { STAMPED_TYPES, useStamped } from "@app/hooks/useStamped"
import { CustomIcon } from "@components/Icon"
import { CustomSelect } from "@components/Select"
import { Stars, ProductReviewStars } from "./ProductReviewStars"
import { CustomLink } from "@components/Link"

type ProductStampedReviewsWidgetProps = {
  product: any
  productId?: string
  pageSize?: number
  showWriteReview?: boolean
}

const ProductStampedReviewsWidget: React.FC<ProductStampedReviewsWidgetProps> = ({
  product,
  productId,
  pageSize = 5,
  showWriteReview = true,
}) => {
  const containerRef = useRef<any>()

  const {
    loading,
    voted,
    reviews,
    sortOptions,
    avatarColours,
    size,
    page,
    pages,
    changePage,
    changeSort,
    changePageSize,
    submitVote,
    recommendedCount,
  } = useStamped({
    productId,
    pageSize,
    type: STAMPED_TYPES.REVIEWS,
    containerRef,
  })

  const hasReviews = useMemo(() => reviews?.total > 0, [reviews?.total])

  const reviewsCount = useMemo(() => reviews?.total || 0, [reviews?.total])

  return (
    <Box maxWidth="100%" pt={11} ref={containerRef}>
      {loading && <Spinner />}
      {!loading && reviews && (
        <>
          <Flex flexDirection={{ base: "column", lg: "row" }} mb={10}>
            <Flex flexDirection="row" w={{ base: "100%", lg: "75%" }}>
              {!hasReviews && (
                <Text variant="label" textAlign="center" fontSize="14px" w="100%" alignSelf="center">
                  No customer reviews yet. Be the first to leave a review.
                </Text>
              )}
              {hasReviews && (
                <>
                  <Box
                    w={{ base: "50%", lg: "32%" }}
                    pl={{ base: "0%", sm: "11%", md: "10%", lg: "5%" }}
                    py={1}
                    borderRight={{ base: "none", lg: "2px solid" }}
                    borderColor="peachy"
                  >
                    <Heading as="h3" fontSize="47px" textAlign="left" pb={1}>
                      {/* {Math.round(reviews?.rating)} <Stars rating={reviews.rating} count={reviews.total} size="xl" ml={2} /> */}
                      {Math.round(reviews?.rating)}
                      <ProductReviewStars product={product} showLink={false} size="xl" productId={productId} hideCount />
                    </Heading>
                    <Text variant="label" textAlign="left" fontSize="14px">
                      Based on {reviewsCount} reviews
                    </Text>
                  </Box>
                  <Box flex="1" py={1} pl={{ base: "1%", lg: "13.5%" }} pr={{ base: "0%", sm: "10%", lg: "0%" }}>
                    <Heading as="h3" fontSize="47px" textAlign="left" pb={1}>
                      {recommendedCount}%
                    </Heading>
                    <Text variant="label" textAlign="left" fontSize="14px">
                      of the community would recommend this product to a friend
                    </Text>
                  </Box>
                </>
              )}
            </Flex>
            <HStack
              flex="1"
              mt={hasReviews ? 8 : { base: 8, lg: 0 }}
              py={1}
              justify={{ base: "center", md: "right" }}
              px={{ base: "0%", sm: "10%", lg: "0" }}
              flexWrap="wrap"
              rowGap={4}
              spacing={[0, 2]}
            >
              <CustomSelect
                options={sortOptions}
                onChange={selected => changeSort(selected?.value)}
                defaultValue={sortOptions?.[0]}
                disabled={loading}
                isReviewsSort
                width={["100%", "50%", "50%", 56]}
                minW={["unset", 56]}
              />
              {showWriteReview && (
                <CustomLink
                  to={`/review/write/${product?.handle}`}
                  styles={{
                    w: ["100%", "50%", "50%", 56],
                    minW: ["unset", 56],
                  }}
                >
                  <Button h="40px" w="full" minW="initial" fontSize="md">
                    Write a review
                  </Button>
                </CustomLink>
              )}
            </HStack>
          </Flex>
          <Flex w="100%" opacity={loading ? "50%" : "initial"} pointerEvents={loading ? "none" : "initial"}>
            <VStack w="100%" flex="1" gap={0} m={0} p={0}>
              {reviews?.data?.map((item: any, index: number) => (
                <Flex key={item?.id} border="1px solid" w="100%" mt="40px !important" flexDirection={{ base: "column", md: "row" }}>
                  <Box display="flex" w={{ base: "100%", md: "24%" }} p={{ base: 6, md: 14 }} bgColor="brand.lightSand" textAlign="left">
                    <HStack align="center" my="auto">
                      <Avatar
                        name={item?.author}
                        size="md"
                        mr={1}
                        bgColor={avatarColours[index % 6]}
                        color="brand.avocado"
                        fontFamily="heading"
                      />
                      <Box>
                        <Heading as="h4" size="h4" fontSize="20px" m={0} lineHeight="14px">
                          {item?.author}
                        </Heading>
                        <Text fontSize="15px">
                          {item?.countryIso && (
                            <Img
                              display="inline"
                              w={3}
                              mr={2}
                              src={`https://cdn.stamped.io/cdn/flags/${item?.countryIso?.toLowerCase()}.svg`}
                            />
                          )}
                          {item?.location}
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                  <Box flex="1" pl="10%" pr={{ base: 10, md: 14 }} pt={{ base: 7, md: 14 }} pb={14} textAlign="left" position="relative">
                    <Text variant="label" textAlign="right" position="absolute" right={7} top={6} display={{ base: "none", sm: "block" }}>
                      {item?.reviewDate?.replaceAll("/", ".")}
                    </Text>
                    <Stars rating={item?.reviewRating} count={5} />
                    <Heading as="h3" size="h3" mb={2} mt={4}>
                      {item?.reviewTitle}
                    </Heading>
                    <Text lineHeight={1.4}>{item?.reviewMessage}</Text>
                    <Box
                      textAlign="right"
                      position="absolute"
                      left="10%"
                      bottom={6}
                      width="full"
                      opacity={voted?.includes(item?.id) ? "50%" : "initial"}
                      pointerEvents={voted?.includes(item?.id) ? "none" : "initial"}
                      display="flex"
                    >
                      <Box as="span" w="100%" position="absolute" left="-100px" top="0" display={{ base: "block", sm: "none" }}>
                        <Text as="span" variant="label" textAlign="left">
                          {item?.reviewDate?.replaceAll("/", ".")}
                        </Text>
                      </Box>
                      <Box as="span" mr={2}>
                        Was this helpful?
                      </Box>
                      <Flex as="span" mr={2} alignItems="center">
                        <CustomIcon
                          name="vote-up"
                          display="inline"
                          cursor="pointer"
                          width={10}
                          height={10}
                          onClick={() => submitVote(item?.id, 1)}
                        />
                        &nbsp;
                        <Text variant="label">{item?.reviewVotesUp}</Text>
                      </Flex>
                      &nbsp;
                      <Flex as="span" mr={2} alignItems="center">
                        <CustomIcon
                          name="vote-down"
                          display="inline"
                          cursor="pointer"
                          width={10}
                          height={10}
                          onClick={() => submitVote(item?.id, -1)}
                        />
                        &nbsp;
                        <Text variant="label"></Text>
                        {item?.reviewVotesDown}
                      </Flex>
                    </Box>
                  </Box>
                </Flex>
              ))}
            </VStack>
          </Flex>
          {hasReviews && (
            <HStack pt={12} justify="right">
              <Text fontSize="sm">
                Showing {Math.min(page * size, reviews?.total)} of {reviews?.total}
              </Text>
              {reviews?.data?.length !== reviews?.total && (
                <Button
                  variant="unstyled"
                  minW="initial"
                  fontSize="sm"
                  fontWeight="semibold"
                  textDecoration="underline"
                  px="4px !important"
                  onClick={() => changePageSize(reviews?.total)}
                >
                  Show all
                </Button>
              )}
              {reviews?.data?.length !== reviews?.total && (
                <Button
                  size="sm"
                  minW="initial"
                  fontSize="sm"
                  px="12px !important"
                  onClick={() => changePage({ next: true })}
                  disabled={page == pages}
                  isLoading={loading}
                >
                  Next
                </Button>
              )}
            </HStack>
          )}
        </>
      )}
    </Box>
  )
}

const MemoProductStampedReviewsWidget = memo(ProductStampedReviewsWidget)
export { MemoProductStampedReviewsWidget as ProductStampedReviewsWidget }
