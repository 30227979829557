import { memo } from "react"
import { Text, HStack } from "@chakra-ui/react"
import { ArrowForwardIcon } from "@chakra-ui/icons"
import { CustomLink } from "@components/Link"

type ProductBrandLinkProps = {
  title?: string
  url?: string
}

const ProductBrandLink: React.FC<ProductBrandLinkProps> = ({ title, url = "" }) => {
  if (!title) return null

  return (
    <CustomLink to={url}>
      <HStack role="group" mb={3}>
        <Text fontWeight={700} fontSize="md">
          {title}
        </Text>
        <ArrowForwardIcon mx={2.5} w={4} _groupHover={{ transform: "translateX(8px)" }} transition="100ms ease-out" />
      </HStack>
    </CustomLink>
  )
}

const MemoProductBrandLink = memo(ProductBrandLink)
export { MemoProductBrandLink as ProductBrandLink }
