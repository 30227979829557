import { memo } from "react"
import { HStack, Tag } from "@chakra-ui/react"
import { useBadgeTitles } from "@app/hooks/useBadges"

type ProductTabBadgesProps = {
  tags?: Array<string>
}

const ProductTabBadges: React.FC<ProductTabBadgesProps> = ({ tags }) => {
  const { badgeTitles } = useBadgeTitles({ allProductTags: tags })

  if (!badgeTitles) return null

  return (
    <HStack wrap="wrap" mb={2} spacing={0} columnGap={2} rowGap={1}>
      {badgeTitles?.map((title: string) => (
        <Tag key={title} variant="pdp-description">
          {title}
        </Tag>
      ))}
    </HStack>
  )
}

const MemoProductTabBadges = memo(ProductTabBadges)
export { MemoProductTabBadges as ProductTabBadges }
