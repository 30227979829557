import { memo } from "react"
import { Text, HStack, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, useDisclosure } from "@chakra-ui/react"
import { InfoOutlineIcon } from "@chakra-ui/icons"
import { useStaticQuery, graphql } from "gatsby"
import { useContent } from "@app/hooks/useContent"

const ProductSubscriptionDetailsTooltip = () => {
  const { getContent } = useContent()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { subscriptionWidget } = useStaticQuery<GatsbyTypes.StaticSubscriptionDetailsQuery>(graphql`
    query StaticSubscriptionDetails {
      subscriptionWidget: sanitySettingSubscriptions {
        additionalSubscriptionDetailsVerbiage
        subscriptionDetailsHowItWorks: _rawSubscriptionDetailsHowItWorks(resolveReferences: { maxDepth: 5 })
        subscriptionPopupTextColor
        subscriptionPopupBgColor
      }
    }
  `)

  const subscriptionDetailsHowItWorks = getContent({ content: subscriptionWidget?.subscriptionDetailsHowItWorks })

  if (!subscriptionWidget) return null

  return (
    <>
      <HStack spacing={1.5}>
        <InfoOutlineIcon cursor="pointer" mr={0} w={3} h={3} onClick={onOpen} />
        <Text fontSize="xs">{subscriptionWidget?.additionalSubscriptionDetailsVerbiage}</Text>
      </HStack>
      <ProductSubscriptionDetailsTooltipModal
        isOpen={isOpen}
        onClose={onClose}
        title={subscriptionWidget?.additionalSubscriptionDetailsVerbiage}
        content={subscriptionDetailsHowItWorks}
      />
    </>
  )
}

type ProductSubscriptionDetailsTooltipModalProps = {
  isOpen: boolean
  onClose: () => void
  title?: string
  content?: any
}

const ProductSubscriptionDetailsTooltipModal: React.FC<ProductSubscriptionDetailsTooltipModalProps> = ({
  isOpen,
  onClose,
  title,
  content,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text fontSize="xl" fontWeight={700}>
            {title}
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={4}>{content}</ModalBody>
      </ModalContent>
    </Modal>
  )
}

const MemoProductSubscriptionDetailsTooltip = memo(ProductSubscriptionDetailsTooltip)
export { MemoProductSubscriptionDetailsTooltip as ProductSubscriptionDetailsTooltip }
