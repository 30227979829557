import { memo, useEffect } from "react"
import {
  Box,
  Text,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react"
import { useShopifyPrice } from "@app/hooks/useShopify"
import { QuestionOutlineIcon } from "@chakra-ui/icons"
import { LOCALE_KEYS, useLocale } from "@app/hooks/useLocale"

import type { ProductVariant } from "shopify-storefront-api-typings"
import { useRewards } from "@app/hooks/useRewards"

// See https://developers.loyaltylion.com/sdk/components/points-for-product

type RewardsProductPointsProps = {
  productId: string
  variant: ProductVariant
}

type RewardsProductPointsInfoModalProps = {
  isOpen: boolean
  onClose: () => void
}

const RewardsProductPointsInfoModal: React.FC<RewardsProductPointsInfoModalProps> = ({ isOpen, onClose }) => {
  const locales = useLocale(LOCALE_KEYS.REWARDS)

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text fontSize="xl" fontWeight={700}>
            {locales?.pointsExplainerPopupTitle}
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={4}>
          <Text fontSize="lg">{locales?.pointsExplainerPopupText}</Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

const RewardsProductPoints: React.FC<RewardsProductPointsProps> = ({ productId, variant }) => {
  const { formattedPrice } = useShopifyPrice(variant, 1)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { refreshRewardsUI } = useRewards()

  useEffect(() => {
    refreshRewardsUI()
  }, [formattedPrice, refreshRewardsUI])

  return (
    <Box key={formattedPrice}>
      <Text as="span" id="ProductPrice" data-lion-price-for-product-id={productId} display="none">
        {formattedPrice}
      </Text>
      <HStack spacing={1.5}>
        <Text fontSize="xs">
          Earn <span data-lion-points-for-product-id={productId}></span> GM points
        </Text>
        <QuestionOutlineIcon cursor="pointer" width={3} onClick={onOpen} />
      </HStack>
      <RewardsProductPointsInfoModal isOpen={isOpen} onClose={onClose} />
    </Box>
  )
}

const MemoRewardsProductPoints = memo(RewardsProductPoints)
export { MemoRewardsProductPoints as RewardsProductPoints }
