import { useShopify } from "./useShopify"
import { useCallback } from "react"

const useShopifyProductRaw = () => {
  const { staticVariantNormaliser } = useShopify()

  const getRawProduct = useCallback(
    (product: any) => {
      if (!product) return { product: null }

      try {
        const rawProduct = product?.shopify?.raw ? JSON.parse(product?.shopify?.raw) : product

        return {
          product: {
            ...rawProduct,
            variants: rawProduct.variants.map(staticVariantNormaliser),
          },
        }
      } catch (err) {
        console.error("Error parsing raw shopify product", product)
        return { product }
      }
    },
    [staticVariantNormaliser]
  )

  return { getRawProduct }
}

export { useShopifyProductRaw }
