import { lazy, Suspense } from "react"
import { useCore } from "@app/hooks/useCore"
import { useMedia } from "@app/hooks/useMedia"
import { useShopify } from "@app/hooks/useShopify"
import { memo, useMemo } from "react"
import "react-inner-image-zoom/lib/InnerImageZoom/styles.min.css"

type ProductMediaProps = {
  product: any
  brandCollection?: any
}

const ProductMediaDesktop = lazy(() => import("./ProductMediaDesktop"))
const ProductMediaMobile = lazy(() => import("./ProductMediaMobile"))

const ProductMedia: React.FC<ProductMediaProps> = ({ product, brandCollection }) => {
  const {
    helpers: { ErrorBoundary },
  } = useCore()
  const { isUnderMedium } = useMedia()
  const { imageNormaliser } = useShopify()

  const hasImages = useMemo(() => !!product?.images?.length, [product?.images?.length])

  const images = useMemo(
    () => product?.images?.map((image: any, idx: number) => ({ key: idx, ...imageNormaliser(image, 1000) })) || [],
    [imageNormaliser, product?.images]
  )

  if (!hasImages) return null

  return (
    <ErrorBoundary>
      <Suspense fallback={null}>
        {isUnderMedium ? <ProductMediaMobile images={images} /> : <ProductMediaDesktop images={images} brandCollection={brandCollection} />}
      </Suspense>
    </ErrorBoundary>
  )
}

export default memo(ProductMedia)
