import { graphql, PageProps } from "gatsby"
import Template from "@components/Product/Product"

export type Props = PageProps<GatsbyTypes.TemplateProductQuery, GatsbyTypes.TemplateProductQueryVariables>

export { Head } from "@components/Head"

export const query = graphql`
  query TemplateProduct($handle: String!) {
    product: sanityProduct(shopify: { handle: { eq: $handle }, deleted: { ne: true }, published: { eq: true } }) {
      ...SanityProductFragment
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      siblings: _rawSiblings(resolveReferences: { maxDepth: 2 })
      related: _rawRelated(resolveReferences: { maxDepth: 3 })
    }
    template: sanityTemplateProduct {
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      additionalRelatedProductsTitle
      additionalBackLinkLabel
      settingShowRelatedProducts
    }
  }
  fragment SanityProductFragment on SanityProduct {
    id
    tabItems: _rawTabItems(resolveReferences: { maxDepth: 3 })
    url
    title
    descriptionHtml
    tags
    image
    shopify {
      id
      handle
      raw
    }
    _type
    sections: _rawContent(resolveReferences: { maxDepth: 10 })
    bonus {
      title
      bgColor
    }
  }
`

const Component: React.FC<Props> = ({ data, ...props }) => <Template {...props} {...data} />
export default Component
