import { memo } from "react"
import { HStack, Text } from "@chakra-ui/react"
import { CustomIcon } from "@components/Icon"

const ProductAfterPay: React.FC = () => {
  return (
    <HStack sx={{ "afterpay-placement": { margin: "unset" } }}>
      <CustomIcon name="afterpay" width={60} height={20} />
      <Text fontSize="xs">Available at checkout</Text>
    </HStack>
  )
}

const MemoProductAfterPay = memo(ProductAfterPay)
export { MemoProductAfterPay as ProductAfterPay }
