import { useState, memo, useCallback, useMemo } from "react"
import { Avatar, Box, Button, Flex, Heading, HStack, Stack, Text, VStack, Spinner } from "@chakra-ui/react"
import { useStamped, STAMPED_TYPES } from "@app/hooks/useStamped"
import { CustomIcon } from "@components/Icon"
import { useFunctions } from "@app/hooks/useFunctions"
import { ControlledInput, ControlledTextarea, FIELD_TYPES, useFormBuilder } from "@app/hooks/useFormBuilder"
import { useConfigContext } from "@app/providers/config"

type ProductStampedQuestionsWidgetProps = {
  productId?: string
  pageSize?: number
}

const ProductStampedQuestionsWidget: React.FC<ProductStampedQuestionsWidgetProps> = ({ productId, pageSize = 5 }) => {
  const [data, setData] = useState({})
  const [questionFormVisible, setQuestionFormVisible] = useState(false)
  const [questionFormSubmitted, setQuestionFormSubmitted] = useState(false)
  const {
    settings: { functions },
  } = useConfigContext()

  const { callFunction } = useFunctions()

  const { loading, voted, questions, avatarColours, size, page, pages, changePage, submitVote } = useStamped({
    productId,
    pageSize,
    type: STAMPED_TYPES.QUESTIONS,
  })

  const { fieldErrors, register, handleSubmit } = useFormBuilder({
    onSubmit: async (data: any) => {
      setQuestionFormSubmitted(true)
      await callFunction(functions.stampedAskQuestion, { name: data.name, email: data.email, questionBody: data.question, productId })
    },
  })

  const handleChange = useCallback(({ target: { type, name, value, checked } }: React.ChangeEvent<HTMLInputElement>) => {
    setData(prevState => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }))
  }, [])

  const hasQuestions = useMemo(() => questions?.length > 0, [questions?.length])

  return (
    <Box pt={11}>
      {loading && <Spinner />}
      {!loading && questions && (
        <>
          <Flex mb={10}>
            <HStack flex="1" mt={9} py={1} justify="right">
              <Button h="40px" w="155px" minW="initial" fontSize="md" onClick={() => setQuestionFormVisible(true)}>
                Ask a question
              </Button>
            </HStack>
          </Flex>
          {questionFormSubmitted && <Text>Your question has been submitted</Text>}
          {!questionFormSubmitted && questionFormVisible && (
            <Box as="form" onSubmit={handleSubmit}>
              <VStack spacing={[2, 4]} w="100%">
                <Stack direction={["column", "row"]} spacing={[2, 4]} w="100%">
                  <ControlledInput
                    type={FIELD_TYPES.FULL_NAME}
                    register={register}
                    onChange={handleChange}
                    fieldErrors={fieldErrors}
                    data={data}
                    showAsterisk
                  />
                  <ControlledInput
                    type={FIELD_TYPES.EMAIL}
                    register={register}
                    onChange={handleChange}
                    fieldErrors={fieldErrors}
                    data={data}
                    showAsterisk
                  />
                </Stack>
                <ControlledTextarea
                  type={FIELD_TYPES.QUESTION}
                  register={register}
                  onChange={handleChange}
                  fieldErrors={fieldErrors}
                  data={data}
                  showAsterisk
                />
              </VStack>
              <Button variant="solid" type="submit" w={["100%", "auto"]} mt={8}>
                Ask a Question
              </Button>
            </Box>
          )}
          <Flex w="100%" opacity={loading ? "50%" : "initial"} pointerEvents={loading ? "none" : "initial"}>
            <VStack flex="1" gap={0} m={0} p={0}>
              {questions.map((item: any, index: number) => (
                <Flex key={item?.id} border="1px solid" w="full" mt="-1px !important" flexDirection={{ base: "column", md: "row" }}>
                  <Box display="flex" w={{ base: "100%", md: "24%" }} p={{ base: 6, md: 14 }} bgColor="brand.lightSand" textAlign="left">
                    <HStack align="center" my="auto">
                      <Avatar
                        name={item?.author}
                        size="md"
                        mr={1}
                        bgColor={avatarColours[index % 6]}
                        color="brand.avocado"
                        fontFamily="heading"
                      />
                      <Box>
                        <Heading as="h4" size="h4" fontSize="20px" m={0} lineHeight="14px">
                          {item?.author}
                        </Heading>
                      </Box>
                    </HStack>
                  </Box>
                  <Box flex="1" pl="10%" pr={14} py={14} textAlign="left" position="relative">
                    <Text variant="label" textAlign="right" position="absolute" right={7} top={6}>
                      {item?.date?.replaceAll("/", ".")}
                    </Text>
                    <Heading as="h4" size="h4" mb={2} mt={4}>
                      {item?.question}
                    </Heading>
                    <Text lineHeight={1.4}>{item?.reply}</Text>
                    <Box
                      position="absolute"
                      textAlign="right"
                      right={7}
                      bottom={6}
                      opacity={voted?.includes(item?.id) ? "50%" : "initial"}
                      pointerEvents={voted?.includes(item?.id) ? "none" : "initial"}
                      display="flex"
                    >
                      <Text variant="label" as="span" mr={2}>
                        Was this helpful?
                      </Text>
                      <Flex as="span" mr={2} alignItems="center">
                        <CustomIcon
                          name="vote-up"
                          display="inline"
                          cursor="pointer"
                          width={10}
                          height={10}
                          onClick={() => submitVote(item?.id, 1)}
                        />{" "}
                        <Text variant="label">{item?.votesUp}</Text>
                      </Flex>
                      <Flex as="span" mr={2} alignItems="center">
                        <CustomIcon
                          name="vote-down"
                          display="inline"
                          cursor="pointer"
                          width={10}
                          height={10}
                          onClick={() => submitVote(item?.id, -1)}
                        />{" "}
                        <Text variant="label">{item?.votesDown}</Text>
                      </Flex>
                    </Box>
                  </Box>
                </Flex>
              ))}
            </VStack>
          </Flex>
          {hasQuestions && (
            <HStack pt={12} justify="right">
              <Text fontSize="sm">Showing {page == pages ? "all" : page * size} questions</Text>
              {page != pages && (
                <Button
                  size="sm"
                  minW="initial"
                  fontSize="sm"
                  px="12px !important"
                  onClick={() => changePage({ next: true })}
                  disabled={page == pages}
                  isLoading={loading}
                >
                  Next
                </Button>
              )}
            </HStack>
          )}
        </>
      )}
    </Box>
  )
}

const MemoProductStampedQuestionsWidget = memo(ProductStampedQuestionsWidget)
export { MemoProductStampedQuestionsWidget as ProductStampedQuestionsWidget }
