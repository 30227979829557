import { memo } from "react"
import { Box, Container, Heading, HStack, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react"
import { ProductStampedReviewsWidget } from "./ProductStampedReviewsWidget"
import { ProductStampedQuestionsWidget } from "./ProductStampedQuestionsWidget"

type ProductReviewsProps = {
  product: any
  title?: string
  showWriteReview?: boolean
  productId?: string
}

const TitleTab = ({ title }: { title: string }) => (
  <Tab _selected={{ borderBottomColor: "brand.avocado" }} fontSize={["md", "2xl"]} pb={[3, 5]}>
    {title}
  </Tab>
)

// const getOkendoStyles = ({ isCustomerNutritionist }: { isCustomerNutritionist: boolean }) => ({
//   ".oke-state-empty-button": { display: isCustomerNutritionist ? "block" : "none" },
//   ".oke-state-empty-text": { visibility: isCustomerNutritionist ? "visible" : "hidden" },
//   ".oke-state-empty-text:after": isCustomerNutritionist
//     ? {}
//     : { content: '"No reviews yet"', visibility: "visible", pos: "absolute", left: "50%", transform: "translateX(-50%)" },
//   ".oke-w-reviews-writeReview": { display: isCustomerNutritionist ? "block" : "none" },
// })

const ProductReviews: React.FC<ProductReviewsProps> = ({ product, title, showWriteReview = true, productId }) => {
  return (
    <Box as="section" pt={[9, 16]} pb={[12, 20]} textAlign="center" overflow="hidden" position="relative">
      {/* TODO: does the below element do anything? if not remove it. if so, add a comment */}
      <a id="reviews"></a>
      <Container>
        <HStack justifyContent={["center", "center", "space-between"]} mb={[4, 4]}>
          <Heading as="p" w="full" fontSize={["2xl", "3xl", "8xl"]} textAlign="center">
            {title || <>Honest Thoughts &amp; Opinions</>}
          </Heading>
        </HStack>
        <Tabs align="center" isFitted defaultIndex={0}>
          <TabList pt={[3, 6, 9]}>
            <TitleTab title="Community" />
            <TitleTab title="Questions" />
          </TabList>
          <TabPanels>
            <TabPanel p={0}>
              <ProductStampedReviewsWidget product={product} productId={productId} showWriteReview={showWriteReview} />
            </TabPanel>
            <TabPanel p={0}>
              <ProductStampedQuestionsWidget productId={productId} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Container>
    </Box>
  )
}

const MemoProductReviews = memo(ProductReviews)
export { MemoProductReviews as ProductReviews }
