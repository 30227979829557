import { memo, useCallback, useMemo } from "react"
import { Box, HStack, VStack, Text, Button } from "@chakra-ui/react"
import { LOCALE_KEYS, useLocale } from "@app/hooks/useLocale"
import { OneTimeOrRecurring } from "./ProductOneTimeOrRecurring"

type ProductVariantsProps = {
  product: any
  loading: boolean | undefined
  selectedOptions: any
  handleOptions: (option: any) => void
  isGiftCardProduct?: boolean
  isSubscriptionAndOneTimeProduct?: boolean
  recurrence?: any
  appliedDiscount?: number | undefined
}

type ProductVariantButtonProps = {
  optionName: string
  optionValue: string
  loading: boolean | undefined
  selectedOptions: Array<any>
  handleButtonClick: (args: any) => void
}

const SelectorLabel = ({ children }: { children: React.ReactNode }) => {
  return (
    <Text fontFamily="heading" fontSize="md" fontWeight={900} mb={2.5}>
      {children}
    </Text>
  )
}

const ProductVariantButton: React.FC<ProductVariantButtonProps> = ({
  optionName,
  optionValue,
  selectedOptions,
  handleButtonClick,
  loading,
}) => {
  const activeOption = selectedOptions?.find(({ name }: { name: string }) => name === optionName)
  const isActiveButton = activeOption?.value === optionValue

  return (
    <Button
      isDisabled={loading}
      key={optionValue}
      onClick={handleButtonClick}
      variant={isActiveButton ? "solid-no-hover" : "outline"}
      borderRadius="46px"
      px={3}
      fontSize="sm"
      height={8}
      minW="unset"
      data-name={optionName}
      data-value={optionValue}
    >
      {optionValue}
    </Button>
  )
}

const ProductVariants: React.FC<ProductVariantsProps> = ({
  product,
  loading,
  selectedOptions,
  handleOptions,
  isGiftCardProduct = false,
  isSubscriptionAndOneTimeProduct = false,
  recurrence,
  appliedDiscount,
}) => {
  // Shopify is adding a default variant for single variant products
  // https://community.shopify.com/c/shopify-apis-and-sdks/adding-new-product-variant-is-automatically-adding-quot-default/td-p/358095
  const variantsWithoutDefault = useMemo(() => product?.options?.filter((option: any) => option.name !== "Title"), [product?.options])
  const hasVariants = !!variantsWithoutDefault?.length

  const locales = useLocale(LOCALE_KEYS.PRODUCT)
  const { purchaseRecurrence, setPurchaseRecurrence } = recurrence || {}
  const variantSelectorText = locales?.variantSelectorPrefix || "Select your"

  const extraStyles = useMemo(
    () =>
      isGiftCardProduct
        ? {
            py: 5,
            px: 4,
            bg: "brand.lightSand",
          }
        : {},
    [isGiftCardProduct]
  )

  const handleButtonClick = useCallback(
    event => {
      const { name, value } = event?.target?.dataset || {}
      handleOptions({ name, value })
    },
    [handleOptions]
  )

  if (!hasVariants && !isSubscriptionAndOneTimeProduct) return null

  return (
    <VStack spacing={4} alignItems="flex-start" {...extraStyles}>
      {isSubscriptionAndOneTimeProduct && (
        <OneTimeOrRecurring
          loading={loading}
          setPurchaseRecurrence={setPurchaseRecurrence}
          purchaseRecurrence={purchaseRecurrence}
          appliedDiscount={appliedDiscount}
        />
      )}
      {variantsWithoutDefault.map((option: any) => (
        <Box key={option?.name}>
          <SelectorLabel>
            {variantSelectorText} {option.name}:
          </SelectorLabel>
          <HStack spacing={0} rowGap={1} columnGap={2} wrap="wrap">
            {option?.values?.map((optionValue: string) => (
              <ProductVariantButton
                key={optionValue?.toString()}
                optionValue={optionValue}
                optionName={option.name}
                loading={loading}
                handleButtonClick={handleButtonClick}
                selectedOptions={selectedOptions}
              />
            ))}
          </HStack>
        </Box>
      ))}
    </VStack>
  )
}

const MemoProductVariants = memo(ProductVariants)
export { MemoProductVariants as ProductVariants }
