import { memo } from "react"
import { Heading, Text } from "@chakra-ui/react"

type Props = {
  product: any
}

const ProductTitle: React.FC<Props> = ({ product }) => (
  <>
    <Heading as="h1" size="h4" mb={2}>
      {product?.title}
    </Heading>

    {product?.type && (
      <Text fontWeight={700} my={4}>
        {product.type}
      </Text>
    )}
  </>
)

const MemoProductTitle = memo(ProductTitle)
export { MemoProductTitle as ProductTitle }
