import { memo } from "react"
import { Box, VStack } from "@chakra-ui/react"

type ProductBonusMessagingProps = {
  bonus: GatsbyTypes.SanityProduct["bonus"]
}

const ProductBonusMessaging: React.FC<ProductBonusMessagingProps> = ({ bonus }) => {
  return (
    <VStack alignItems="flex-start" spacing={4.5} mt={4}>
      <Box bg={bonus?.bgColor || "brand.yellow"} p={3.5} borderRadius="60px" textAlign="center" fontSize="sm" w="full">
        {bonus?.title}
      </Box>
    </VStack>
  )
}

const MemoProductBonusMessaging = memo(ProductBonusMessaging)
export { MemoProductBonusMessaging as ProductBonusMessaging }
