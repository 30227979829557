import { useCallback, memo } from "react"
import { navigate } from "gatsby"
import { Box, HStack, Select, Text } from "@chakra-ui/react"
import { useConfigContext } from "@app/providers/config"

type Props = {
  title: string
  product: any
  loading: boolean
  siblings?: Array<any> | undefined
}

const ProductSiblings: React.FC<Props> = ({ title, product, siblings: allSiblings, loading }) => {
  const {
    settings: { routes },
  } = useConfigContext()

  const handleChange = useCallback(
    (value: string) => {
      navigate(`${routes.PRODUCT}/${value}`)
    },
    [routes.PRODUCT]
  )

  const siblings = allSiblings?.filter(item => item?.shopify?.handle)

  if (!siblings?.length) return null

  return (
    <>
      {siblings.length > 0 && (
        <HStack spacing={4} mt={2}>
          <Box>
            <Text>{title}</Text>
            <Select onChange={({ target: { value } }) => handleChange(value)} value={product.handle} isDisabled={loading}>
              <option key={product.handle?.toString()} value={product.handle}>
                {product.title}
              </option>
              {siblings.map((item: any) => (
                <option key={item.shopify.handle} value={item.shopify.handle}>
                  {item.title}
                </option>
              ))}
            </Select>
          </Box>
        </HStack>
      )}
    </>
  )
}

export default memo(ProductSiblings)
