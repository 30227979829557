import { memo } from "react"
import { Box, Text, Accordion, AccordionItem, AccordionButton, AccordionPanel } from "@chakra-ui/react"
import { AddIcon, MinusIcon } from "@chakra-ui/icons"
import { LOCALE_KEYS, useLocale } from "@app/hooks/useLocale"
import { useMedia } from "@app/hooks/useMedia"
import { CustomTable } from "@components/Table"
import { ProductTabBadges } from "./ProductTabBadges"

type ProductAccordionItemsProps = {
  product: any
  tabItems?: GatsbyTypes.SanityProduct["tabItems"]
}

type ProductAccordionItemProps = {
  title: GatsbyTypes.SanityTabItemTable["title"]
  text: GatsbyTypes.SanityTabItemTable["text"]
  table?: GatsbyTypes.SanityTabItemTable["table"]
  locales?: any
  isDescriptionTab?: boolean
  tags?: Array<string>
}

const ExpandIcon = ({ expanded }: { expanded: boolean }) => (expanded ? <MinusIcon w={3} /> : <AddIcon w={3} />)

const ProductAccordionItemTable = ({ table, locales }: any) => {
  const { isMedium } = useMedia()
  const column2Title = isMedium ? locales?.tableColumn2TitleDesktop : locales?.tableColumn2TitleMobile
  const column3Title = isMedium ? locales?.tableColumn3TitleDesktop : locales?.tableColumn3TitleMobile

  return <CustomTable table={table} fontSize="sm" column2Title={column2Title} column3Title={column3Title} />
}

const ProductAccordionItem: React.FC<ProductAccordionItemProps> = ({ title, text, table, locales, tags, isDescriptionTab }) => {
  return title && (text || table) ? (
    <AccordionItem borderBottom="1.5px" borderTop="unset" mb={3}>
      {({ isExpanded }) => (
        <>
          <AccordionButton px={0} fontSize="xl" _hover={{ bgColor: "unset" }}>
            <Box flex="1" textAlign="left" py={1}>
              {title}
            </Box>
            <ExpandIcon expanded={isExpanded} />
          </AccordionButton>
          <AccordionPanel px={0}>
            {isDescriptionTab && <ProductTabBadges tags={tags} />}
            <Text fontSize="md" lineHeight="21px" whiteSpace="break-spaces" dangerouslySetInnerHTML={{ __html: text || "" }} />
            {!!table && <ProductAccordionItemTable table={table} locales={locales} />}
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  ) : null
}

const ProductAccordionItems: React.FC<ProductAccordionItemsProps> = ({ tabItems }) => {
  const hasTabItems = !!tabItems?.length
  const locales = useLocale(LOCALE_KEYS.PRODUCT)

  return (
    <Accordion allowToggle defaultIndex={[0]} mt={6}>
      {hasTabItems && tabItems?.map((tabItem: any) => <ProductAccordionItem key={tabItem?._key} {...tabItem} locales={locales} />)}
    </Accordion>
  )
}

const MemoProductAccordionItems = memo(ProductAccordionItems)
export { MemoProductAccordionItems as ProductAccordionItems }
